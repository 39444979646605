import { useStaticQuery, graphql } from 'gatsby';

const useClubchairPartnersPage = () => {
    const { sanityPageClubchairPartners: PageClubchairPartners } = useStaticQuery(graphql`
        query {
            sanityPageClubchairPartners {
                bannerImage {
                    image {
                        ...ImageWithPreview
                    }
                    alt
                }
                title {
                    en
                    se
                }
                description {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                chairPartnersMen {
                    title
                    url
                }
                chairPartnersWomen {
                    title
                    url
                }
            }
        }
    `);

    return PageClubchairPartners;
};

export default useClubchairPartnersPage;
