import React from 'react';

import * as style from 'styles/components/subPartners/list.module.scss';

const PartnerList = ({ headline, partners }) => {
    partners.sort((a, b) => a.title.localeCompare(b.title, 'sv', { sensitivity: 'base' }));
    const data = partners.reduce((r, e) => {
        const alphabet = e.title[0];
        if (!r[alphabet]) r[alphabet] = { alphabet, partner: [e] }
        else r[alphabet].partner.push(e);
        return r;
      }, {});
      const sortedPartners = Object.values(data);

    return (
        <div className={style.subPartnersList__alphabetical}>
            <h2 className="title-sm bold">
                {headline}
            </h2>
            <div className={style.subPartnersList__alphabeticalContainer}>
                {sortedPartners.map((item, index) => (
                    <div key={index} className={style.subPartnersList__alphabeticalList}>
                        <ul>
                            {item.partner.map((partner, indexMember) => (
                                <li key={indexMember}>
                                    {partner.url ? (
                                        <a href={partner.url} target="_blank" rel="noreferrer" className="text-lg">
                                            {partner.title}
                                        </a>
                                    ) : (
                                        <p className="text-lg">{partner.title}</p>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PartnerList;
